import { RiArrowUpSLine, RiQuestionnaireLine } from '@remixicon/react'
import { useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'

import { iconMap } from '@/utils'
import { cn } from '@/utils/clsx'

import { Settings } from '@/types'

interface SuggestedQuestionsProps {
  getAnswer: (
    domain: boolean,
    question: string,
    agent?: string,
    isCommand?: boolean
  ) => void
  settings?: Settings
}

const SuggestedQuestions: React.FC<SuggestedQuestionsProps> = ({
  getAnswer,
  settings,
}) => {
  const searchParams = useSearchParams()
  const [suggestedQuestions, setSuggestedQuestions] = useState<
    {
      icon: JSX.Element
      heading: string
      message: string
      display?: boolean
    }[]
  >([])

  useEffect(() => {
    if (!settings || !settings.useDefaultQuestions) {
      return
    }
    const IconComponent1 = iconMap[settings.defaultQuestion1?.icon ?? '']
    const IconComponent2 = iconMap[settings.defaultQuestion2?.icon ?? '']
    const IconComponent3 = iconMap[settings.defaultQuestion3?.icon ?? '']
    const IconComponent4 = iconMap[settings.defaultQuestion4?.icon ?? '']
    setSuggestedQuestions([
      {
        icon: IconComponent1 ? (
          <IconComponent1 size='20px' />
        ) : (
          <RiQuestionnaireLine size='20px' />
        ),
        heading: settings.defaultQuestion1?.heading ?? '',
        message: settings.defaultQuestion1?.question ?? '',
        display:
          !!settings.defaultQuestion1?.heading &&
          !!settings.defaultQuestion1.question,
      },
      {
        icon: IconComponent2 ? (
          <IconComponent2 size='20px' />
        ) : (
          <RiQuestionnaireLine size='20px' />
        ),
        heading: settings.defaultQuestion2?.heading ?? '',
        message: settings.defaultQuestion2?.question ?? '',
        display:
          !!settings.defaultQuestion2?.heading &&
          !!settings.defaultQuestion2?.question,
      },
      {
        icon: IconComponent3 ? (
          <IconComponent3 size='20px' />
        ) : (
          <RiQuestionnaireLine size='20px' />
        ),
        heading: settings.defaultQuestion3?.heading ?? '',
        message: settings.defaultQuestion3?.question ?? '',
        display:
          !!settings.defaultQuestion3?.heading &&
          !!settings.defaultQuestion3?.question,
      },
      {
        icon: IconComponent4 ? (
          <IconComponent4 size='20px' />
        ) : (
          <RiQuestionnaireLine size='20px' />
        ),
        heading: settings.defaultQuestion4?.heading ?? '',
        message: settings.defaultQuestion4?.question ?? '',
        display:
          !!settings.defaultQuestion4?.heading &&
          !!settings.defaultQuestion4?.question,
      },
    ])
  }, [settings])

  useEffect(() => {
    if (searchParams.get('sQuestions') && searchParams.get('sHeadings')) {
      const questions = searchParams.get('sQuestions')?.split(',') ?? []
      const headings = searchParams.get('sHeadings')?.split(',') ?? []
      const icons = searchParams.get('sIcons')
        ? searchParams.get('sIcons')?.split(',') ?? []
        : ''
      if (questions.length === headings.length) {
        const newSuggestedQuestions = questions.map((question, index) => {
          const IconComponent = icons[index]
            ? iconMap[icons[index] ?? '']
            : null
          return {
            icon: IconComponent ? (
              <IconComponent size='20px' />
            ) : (
              <RiQuestionnaireLine size='20px' />
            ),
            heading: headings[index] ?? '',
            message: question,
          }
        })
        setSuggestedQuestions(newSuggestedQuestions)
      }
    }
  }, [searchParams])

  return (
    <div
      className={cn(
        'relative left-1/2 grid w-full -translate-x-1/2 grid-cols-1 gap-3 p-4 fade-in sm:max-w-[38em]',
        suggestedQuestions.filter((q) => q.display).length > 1
          ? 'sm:grid-cols-2'
          : ''
      )}
    >
      {suggestedQuestions
        .filter((q) => q.display === undefined || q.display)
        .map(({ icon, heading, message }, index) => (
          <section
            key={index}
            className='slide-from-bottom'
            onClick={() => getAnswer(true, message)}
          >
            <div className='group flex h-full cursor-pointer items-center justify-between rounded-xl bg-surface/80 bg-gradient-to-b px-5 py-3 text-on-surface drop-shadow-sm hover:drop-shadow-md'>
              <div className='flex items-center gap-2 text-left text-sm'>
                {icon}
                {heading}
              </div>
              <RiArrowUpSLine className='size-4 opacity-0 transition-all group-hover:opacity-100' />
            </div>
          </section>
        ))}
    </div>
  )
}

export default SuggestedQuestions
