import { RiLogoutBoxRLine } from '@remixicon/react'
import { Avatar, Button, Divider, Popover } from 'antd'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'

import useLoadingState from '@/hooks/context/useLoadingState'
import useAuth from '@/hooks/useAuth'

import { API_URL } from '@/constants/env'

const ProfileBadge: React.FC = () => {
  const { t } = useTranslation()
  const { startLoading } = useLoadingState()
  const { user } = useAuth()

  if (!user?.email) {
    return null
  }

  return (
    <Popover
      placement='bottomRight'
      trigger='click'
      content={
        <div className='flex min-w-[200px] max-w-[300px] flex-col'>
          <div className='truncate text-base font-semibold'>{user?.name}</div>
          <div className='truncate text-sm'>{user?.email}</div>
          <Divider plain className='my-3' />
          <Link
            href={`${API_URL}/auth/logout`}
            className='w-fit text-base'
            onClick={() => startLoading()}
          >
            <Button
              className='flex items-center'
              icon={<RiLogoutBoxRLine className='size-4' />}
            >
              {t('logout')}
            </Button>
          </Link>
        </div>
      }
    >
      <Avatar className='cursor-pointer bg-primary text-on-primary'>
        <span className='text-lg'>
          {(user.name ?? '').slice(0, 1).toUpperCase()}
        </span>
      </Avatar>
    </Popover>
  )
}

export default ProfileBadge
