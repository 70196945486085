import { RiSave3Line } from '@remixicon/react'
import { Button, Card, Form, Input } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IGeneratedForm } from '@/types/chatbot'

const { TextArea } = Input

interface GeneratedFormProps extends IGeneratedForm {
  getAnswer: (
    domain: boolean,
    question: string,
    agent?: string
  ) => Promise<void>
}

const GeneratedForm: React.FC<GeneratedFormProps> = ({ items, getAnswer }) => {
  const [form] = Form.useForm()
  const [dirty, setDirty] = useState(false)
  const { t } = useTranslation()

  const saveSettings = async () => {
    const question = items
      .map((item) => `${item.label}: ${form.getFieldValue(item.label)}`)
      .join('\n')

    getAnswer(true, question)
  }

  return (
    <Card>
      <Form
        form={form}
        onFinish={saveSettings}
        onValuesChange={() => {
          setDirty(true)
        }}
      >
        <div className='flex flex-col gap-6'>
          {items.map((item, index) => (
            <div className='items flex gap-4 sm:gap-20' key={index}>
              <div className='flex w-[150px] flex-col gap-1 pt-1'>
                {item.label}
              </div>
              <Form.Item
                name={item.label}
                rules={[{ type: item.type, required: item.isRequired }]}
                className='w-full'
              >
                {item.multiple_rows ? (
                  <TextArea
                    placeholder={item.placeholder}
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                ) : (
                  <Input placeholder={item.placeholder} />
                )}
              </Form.Item>
            </div>
          ))}

          <div className='m-0 flex justify-end'>
            <Form.Item className='m-0'>
              <Button
                className='flex items-center'
                icon={<RiSave3Line className='size-5' />}
                htmlType='submit'
                disabled={!dirty}
              >
                {t('submit')}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Card>
  )
}

export default GeneratedForm
