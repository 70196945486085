import axios from 'axios'
import useSWR from 'swr'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { Settings } from '@/types'

const allSettings = [
  'model',
  'use-streaming',
  'use-drafting',
  'language',
  'email-format-prompt',
  'export-format-prompt',
  'domain-prompt',
  'nodomain-prompt',
  'use-default-questions',
  'default-question-1',
  'default-question-2',
  'default-question-3',
  'default-question-4',
  'default-heading-1',
  'default-heading-2',
  'default-heading-3',
  'default-heading-4',
  'personality-prompt',
  'default-icon-1',
  'default-icon-2',
  'default-icon-3',
  'default-icon-4',
]

const mapSettings = (data: any): Settings => {
  return {
    model: data['model']!,
    useStreaming: data['use-streaming']! === 'true',
    useDrafting: data['use-drafting']! === 'true',
    language: data['language']!,
    emailFormatPrompt: data['email-format-prompt']!,
    exportFormatPrompt: data['export-format-prompt']!,
    domainPrompt: data['domain-prompt']!,
    noDomainPrompt: data['nodomain-prompt']!,
    personalityPrompt: data['personality-prompt']!,
    useDefaultQuestions: data['use-default-questions']! === 'true',
    defaultQuestion1: {
      icon: data['default-icon-1']!,
      heading: data['default-heading-1']!,
      question: data['default-question-1']!,
    },
    defaultQuestion2: {
      icon: data['default-icon-2']!,
      heading: data['default-heading-2']!,
      question: data['default-question-2']!,
    },
    defaultQuestion3: {
      icon: data['default-icon-3']!,
      heading: data['default-heading-3']!,
      question: data['default-question-3']!,
    },
    defaultQuestion4: {
      icon: data['default-icon-4']!,
      heading: data['default-heading-4']!,
      question: data['default-question-4']!,
    },
  }
}

const useSettings = (agent?: string) => {
  const config = {
    method: 'get',
    withCredentials: true,
    params: { keys: allSettings, agent },
    ...configHeader,
  }

  const { data, error, isLoading, mutate } = useSWR(
    !agent ? null : ['settings', agent],
    async () => axios(`${API_URL}/settings`, config),
    { revalidateOnFocus: false }
  )

  if (error) {
    console.error(error)
  }

  return {
    settings: data ? mapSettings(data?.data.setting) : undefined,
    isLoading,
    error,
    mutate,
  }
}

export default useSettings
