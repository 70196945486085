'use client'

import { Fragment, useContext, useState } from 'react'

import { IndexPageContext } from '@/components/Chatbot'

import { cn } from '@/utils/clsx'

import Answer from './Answer/Answer'
import ReferencesModal from './Answer/ReferencesModal'

import { Agent } from '@/types'
import { IQuestion } from '@/types/chatbot'

interface AnswersProps {
  questions: IQuestion[]
  getAnswer: (
    domain: boolean,
    question: string,
    agent?: string
  ) => Promise<void>
  hideHeader?: boolean
  adminChatbot?: boolean
  isCtaOpen?: boolean
  onSelectedDraft: (draft: string) => void
  agents?: Agent[]
}

const Answers: React.FC<AnswersProps> = ({
  questions,
  getAnswer,
  hideHeader,
  adminChatbot,
  onSelectedDraft,
  agents,
}) => {
  const isIndexPage = useContext(IndexPageContext)
  const [referencesQuestionInfo, setReferencesQuestionInfo] = useState<{
    documents: string[]
    titles: string[]
    googleDriveUrls: string[]
  }>({ documents: [], titles: [], googleDriveUrls: [] })

  return (
    <>
      <div
        id='chat-container'
        className={cn(
          'hide-scrollbar flex w-full flex-col overflow-hidden overflow-y-auto pb-28',
          !adminChatbot ? 'h-screen' : 'h-[100%]',
          !hideHeader &&
            (isIndexPage
              ? 'items-center pt-24 lg:pt-32'
              : 'pt-16 sm:h-[40rem] sm:max-h-[80vh]')
        )}
      >
        {questions.map(({ messages, question }, index1) =>
          messages.map((msg, index2) => (
            <Fragment key={`${index1}-${index2}`}>
              {msg.message !== '' && msg.message !== 'NO ANSWER' && (
                <Answer
                  question={question}
                  questionInfo={msg}
                  getAnswer={getAnswer}
                  onSelectedDraft={onSelectedDraft}
                  onReferencesClick={(documents, titles, googleDriveUrls) => {
                    setReferencesQuestionInfo({
                      documents,
                      titles,
                      googleDriveUrls,
                    })
                  }}
                  isLastAnswer={
                    index1 === questions.length - 1 &&
                    index2 === messages.length - 1
                  }
                  agents={agents}
                  className={isIndexPage ? 'w-full sm:max-w-[60em]' : ''}
                />
              )}
            </Fragment>
          ))
        )}
        <div id='last-answer' />
      </div>
      <ReferencesModal
        documents={referencesQuestionInfo.documents}
        titles={referencesQuestionInfo.titles}
        googleDriveUrls={referencesQuestionInfo.googleDriveUrls}
      />
    </>
  )
}

export default Answers
