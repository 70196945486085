import { RiDriveLine, RiFileLine } from '@remixicon/react'
import Link from 'next/link'

interface ReferenceProps {
  document: string
  title?: string
  googleDriveUrl?: string
}

const Reference: React.FC<ReferenceProps> = ({
  document,
  title,
  googleDriveUrl,
}) => {
  if (!googleDriveUrl) {
    return (
      <div className='flex w-full items-center gap-2 text-left'>
        <RiFileLine className='size-4 shrink-0' />
        <div className='max-w-sm truncate'>
          {document}: {title}
        </div>
      </div>
    )
  }

  return (
    <Link href={googleDriveUrl} passHref target='_blank' className='w-full'>
      <div className='flex w-full items-center gap-2 text-left'>
        <RiDriveLine className='size-4 shrink-0' />
        <div className='max-w-sm truncate'>
          {document}: {title}
        </div>
      </div>
    </Link>
  )
}

export default Reference
