import axios from 'axios'
import useSWR from 'swr'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

export interface ConversationDetails {
  logId: string
  question: string
  answer: string
  agent: string
  timestamp: number
  domain: boolean
  feedbackScore: number
}

const useConversation = (conversationId?: string) => {
  const config = {
    method: 'get',
    withCredentials: true,
    ...configHeader,
  }

  const { data, error, isLoading, mutate } = useSWR(
    !conversationId ? null : ['conversation', conversationId],
    async () => axios(`${API_URL}/conversations/${conversationId}`, config),
    { revalidateOnFocus: false }
  )

  return {
    conversation: error
      ? []
      : (data?.data.conversation as ConversationDetails[]),
    isLoading,
    error,
    mutate,
  }
}

export default useConversation
